import { defineStore } from 'pinia';

export const useMapRestaurantsStore = defineStore('mapRestaurantsStore', () => {
  const selectedCluster = ref<{ action: string; id: string }>({ action: '', id: '' });

  const updateSelectedClusterId = (id: string, action: string) => {
    selectedCluster.value = { action, id };
  };

  return {
    selectedCluster,
    updateSelectedClusterId
  };
});
