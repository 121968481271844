<template>
  <section
    :class="[
      'Map__container',
      { 'Map__container--restaurant': isRestaurant },
      {
        'Map__container--inspiration': variant === MapVariant.INSPIRATION
      }
    ]"
  >
    <p aria-level="2" class="accessibility-sr-only" role="heading" v-if="cityName">{{ $t('pages.map.srTitle', { cityName: cityName }) }}</p>
    <GoogleMap
      :api-key="apiKey"
      :center="currentCenter"
      :clickable-icons="false"
      :fullscreen-control="false"
      :map-type-control="false"
      :street-view-control="false"
      :styles="[mapStyles]"
      :zoom="14"
      class="Map__google"
      pan-control
      ref="mapRef"
      v-if="displayMap"
    >
      <CustomMarker :key="marker.id" :options="{ position: marker.position, anchorPoint: 'BOTTOM_CENTER' }" v-for="marker in markers">
        <ux-atoms-map-marker
          :cluster-length="marker.count"
          :disabled="isRestaurant"
          :hidden="!mapRef?.mapTilesLoaded"
          :restaurant-code="marker.id"
          :restaurants-names="marker.name"
          :selected="currentRestaurantId === marker.id && !isRestaurant"
          @click="findRestaurant(marker.id)"
        />
      </CustomMarker>

      <CustomMarker :options="{ position: userGeo, anchorPoint: 'BOTTOM_CENTER' }">
        <div class="Map__user-pin" v-if="userGeo?.lat && userGeo?.lng"></div>
      </CustomMarker>

      <CustomControl position="BOTTOM_CENTER">
        <button
          :aria-label="$t('pages.map.getMyCurrentLocation')"
          :title="$t('ux.molecules.map.getMyCurrentLocation')"
          @click="goToUserGeo($event)"
          class="Map__control-button"
        >
          <ux-atoms-icon name="location" size="s" />
        </button>
      </CustomControl>
    </GoogleMap>

    <Teleport to="body">
      <ux-molecules-modal
        :event-element="modalTarget"
        button-no-border
        button-position="right"
        has-label-id
        header-text=""
        size="small"
        v-model="authModalOpen"
      >
        <template #header>
          <ux-atoms-typo as="p" color="dark" variant="text-heading-01">{{ $t('ux.molecules.map.authorizationTitle') }}</ux-atoms-typo>
        </template>
        <ux-atoms-typo as="p" color="dark" variant="text-regular">{{ $t('ux.molecules.map.authorization') }}</ux-atoms-typo>
      </ux-molecules-modal>
    </Teleport>
  </section>
</template>

<script setup lang="ts">
import { CustomControl, CustomMarker, GoogleMap } from 'vue3-google-map';

import { MAX_USER_GEO_RANGE_KM } from '~/helpers/constants';
import { getPageName } from '~/helpers/getPageName';
import { distance } from '~/helpers/haversine';

import { MapVariant, coordType, markerType } from './types.js';

export interface MapProps {
  box?: { bottomRight: coordType; topLeft: coordType } | null;
  center: coordType;
  cityName?: string;
  currentlyHoveredRestaurantId?: string;
  displayGeo?: boolean;
  firstRestaurantId?: string;
  markers: markerType[];
  variant?: MapVariant;
}

const props = withDefaults(defineProps<MapProps>(), {
  box: undefined,
  center: undefined,
  cityName: undefined,
  currentlyHoveredRestaurantId: undefined,
  displayGeo: false,
  firstRestaurantId: undefined,
  markers: undefined,
  variant: MapVariant.DEFAULT
});

const store = useMapRestaurantsStore();
const apiKey = useRuntimeConfig().public.googleApiKey;
const route = useRoute();

const emit = defineEmits(['map::selected-restaurant']);

const isRestaurant = computed(() => props.variant === MapVariant.RESTAURANT);

const mapStyles = {
  elementType: 'labels',
  featureType: 'poi',
  stylers: [
    {
      visibility: 'off'
    }
  ]
};

const mapRef = ref();
const bounds = ref();
const defaultBounds = ref();
const modalTarget = ref<HTMLElement | null>(null);
const userGeo = ref<coordType>();
const authModalOpen = ref<boolean>(false);
const currentRestaurantId = computed(() => store.selectedCluster.id || props.firstRestaurantId);
const currentCenter = ref<coordType>(props.center);
const userRestaurantsBox = ref<{ bottomRight: coordType; topLeft: coordType } | null | undefined>(props.box);
const displayMap = ref(true);
const { isDesktop } = useCurrentWindowSize();

const userIsCloseEnough = computed(() => {
  if (userGeo.value?.lat && userGeo.value?.lng) {
    return distance(userGeo.value.lat, userGeo.value.lng, props.center.lat, props.center.lng, 'K') <= MAX_USER_GEO_RANGE_KM;
  }
  return false;
});

const offsetMapCenter = () => {
  if (!isRestaurant.value) {
    if (!window?.matchMedia(`(min-width: 1024px)`).matches) {
      mapRef?.value?.map.panBy(0, 125);
    } else {
      mapRef?.value?.map.panBy(-300, 0);
    }
  }
};

const defineBounds = () => {
  bounds.value = new mapRef.value.api.LatLngBounds();
  if (mapRef?.value?.api && (props.box || userRestaurantsBox.value)) {
    bounds.value.extend(userRestaurantsBox.value?.bottomRight ?? props.box?.bottomRight);
    bounds.value.extend(userRestaurantsBox.value?.topLeft ?? props.box?.topLeft);
    mapRef.value.map.fitBounds(bounds.value);
    panToBounds();
  } else if (defaultBounds.value.bottomRight.lat !== Infinity) {
    bounds.value.extend({ lat: defaultBounds.value.bottomRight.lat, lng: defaultBounds.value.bottomRight.lng });
    bounds.value.extend({ lat: defaultBounds.value.topLeft.lat, lng: defaultBounds.value.topLeft.lng });
    mapRef.value.map.fitBounds(bounds.value);
    panToBounds();
  }

  if (!props.markers || props.markers?.length === 0) {
    mapRef?.value?.map?.setZoom(14);
  }
};

const panToBounds = () => {
  if (props.variant !== MapVariant.INSPIRATION) {
    const leftPadding = !isDesktop.value ? 10 : 750;
    const bottomPadding = !isDesktop.value ? 360 : 10;
    mapRef?.value?.map.panToBounds(bounds.value, { bottom: bottomPadding, left: leftPadding, right: 10, top: 30 });
  } else {
    mapRef?.value?.map.panToBounds(bounds.value, 10);
  }
};

const findRestaurant = (restaurantCode: string) => {
  if (!isRestaurant.value) {
    store.updateSelectedClusterId(restaurantCode, 'scroll');
  }
  emit('map::selected-restaurant', restaurantCode);
};

const getUserGeo = () => {
  // https://developers.google.com/maps/documentation/javascript/geolocation?hl=fr
  if (getPageName(route.name)?.includes('map___') && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(loadUserGeo);
  }
};

const loadUserGeo = (position: { coords: { latitude: any; longitude: any } }) => {
  userGeo.value = {
    lat: position?.coords?.latitude,
    lng: position?.coords?.longitude
  };

  // if user is closer than 50km to calc user box
  if (userIsCloseEnough.value) {
    calcUserRestaurantsBox();
  }

  // reload map if geo is known
  if (userGeo.value?.lat && userGeo.value?.lng) {
    reloadMap();
  }
};

const goToUserGeo = (e: MouseEvent): void => {
  if (!userGeo.value) {
    toggleOpeningHoursModal(e);
  } else {
    mapRef?.value?.map?.setCenter(userGeo.value);
    offsetMapCenter();
  }
};

const toggleOpeningHoursModal = (event: Event) => {
  modalTarget.value = event.target as HTMLElement;
  authModalOpen.value = !authModalOpen.value;
};

const calcDefaultBounds = () => {
  const maxLat = Math.max(...props.markers.map((m) => m.position.lat));
  const minLat = Math.min(...props.markers.map((m) => m.position.lat));
  const maxLng = Math.max(...props.markers.map((m) => m.position.lng));
  const minLng = Math.min(...props.markers.map((m) => m.position.lng));

  defaultBounds.value = {
    bottomRight: { lat: minLat, lng: maxLng },
    topLeft: { lat: maxLat, lng: minLng }
  };
};

const calcUserRestaurantsBox = () => {
  if (props.box) {
    calcUserRestaurantsPropsBox();
  } else {
    calcUserDefaultRestaurantsBox();
  }
};

// When we have props.box on city pages
const calcUserRestaurantsPropsBox = () => {
  if (!userGeo.value || !props.box) {
    return;
  }

  const userLat = userGeo.value.lat;
  const userLng = userGeo.value.lng;

  const currentBottomRightLat = props.box.bottomRight?.lat ?? Infinity;
  const currentBottomRightLng = props.box.bottomRight?.lng ?? -Infinity;
  const currentTopLeftLat = props.box.topLeft?.lat ?? -Infinity;
  const currentTopLeftLng = props.box.topLeft?.lng ?? Infinity;

  // Calculate the required expansion to center the userGeo
  const latDifference = Math.max(
    Math.abs(userLat - currentTopLeftLat), // Distance to top edge
    Math.abs(userLat - currentBottomRightLat) // Distance to bottom edge
  );

  const lngDifference = Math.max(
    Math.abs(userLng - currentTopLeftLng), // Distance to left edge
    Math.abs(userLng - currentBottomRightLng) // Distance to right edge
  );

  // Adjust the box to center the userGeo
  const newTopLeftLat = userLat + latDifference; // Higher latitude (north)
  const newBottomRightLat = userLat - latDifference; // Lower latitude (south)
  const newTopLeftLng = userLng - lngDifference; // Lower longitude (west)
  const newBottomRightLng = userLng + lngDifference; // Higher longitude (east)

  userRestaurantsBox.value = {
    bottomRight: {
      lat: newBottomRightLat,
      lng: newBottomRightLng
    },
    topLeft: {
      lat: newTopLeftLat,
      lng: newTopLeftLng
    }
  };
};

// When we don't have props.box available, on PoI pages
const calcUserDefaultRestaurantsBox = () => {
  userRestaurantsBox.value = {
    bottomRight: {
      lat: Math.min(userGeo.value?.lat ?? Infinity, defaultBounds.value.bottomRight.lat),
      lng: Math.max(userGeo.value?.lng ?? -Infinity, defaultBounds.value.bottomRight.lng)
    },
    topLeft: {
      lat: Math.max(userGeo.value?.lat ?? -Infinity, defaultBounds.value.topLeft.lat),
      lng: Math.min(userGeo.value?.lng ?? Infinity, defaultBounds.value.topLeft.lng)
    }
  };
};

// TODO : this is creating an internal bug in the GOOGLE MAP lib
// it is due to the fact that we can't reload the map automatically
// for now we are keeping this for now as it's needed for the blue dot refresh
const reloadMap = async () => {
  displayMap.value = false;
  await new Promise((resolve) => setTimeout(resolve, 500));
  displayMap.value = true;
};

onBeforeMount(() => {
  calcDefaultBounds();
  getUserGeo();
});

onMounted(() => {
  // mapRef changes on mounted, so we have to watch it once only after mounted
  watch(
    () => mapRef?.value?.mapTilesLoaded,
    (mapTilesLoaded) => {
      if (!mapTilesLoaded) return;

      if (!isRestaurant.value || props.variant === MapVariant.INSPIRATION) {
        defineBounds();
      }
    }
  );
  if (props.variant === MapVariant.INSPIRATION) {
    watch(
      () => props.markers,
      () => {
        calcDefaultBounds();
        defineBounds();
      }
    );
  }
});
</script>
<style lang="scss">
@use 'Map.scss';
</style>
