export type coordType = {
  lat: number;
  lng: number;
};

export type markerType = {
  count?: number;
  id: string;
  name: string[];
  position: coordType;
};

export enum MapVariant {
  DEFAULT = 'default',
  INSPIRATION = 'inspiration',
  RESTAURANT = 'restaurant'
}
