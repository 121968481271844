<template>
  <button :class="[`Map-marker`, { 'Map-marker--selected': selected, 'Map-marker--hidden': hidden }]">
    <span class="Map-marker__counter" v-if="clusterLength > 1">{{ clusterLength }}</span>
    <span class="accessibility-sr-only" v-if="srText">{{ srText }}</span>
    <ux-atoms-icon name="restaurant" size="m" />
  </button>
</template>
<script lang="ts" setup>
export interface MapMarkerProps {
  clusterLength?: number;
  hidden: boolean;
  restaurantCode: string;
  restaurantsNames: string[] | undefined;
  selected: boolean;
}

const props = withDefaults(defineProps<MapMarkerProps>(), {
  clusterLength: 0,
  hidden: true,
  restaurantCode: '',
  restaurantsNames: undefined,
  selected: false
});

const { t } = useI18n();

const srText = computed(
  () =>
    `${t('ux.atoms.mapMarker.details', { restaurantName: props.restaurantsNames?.join(', ') })} ${props.selected ? t('ux.atoms.mapMarker.srText', { count: props.clusterLength }) : ''}`
);
</script>
<style lang="scss">
@use 'MapMarker';
</style>
